jQuery(document).ready(function ($) {

    function onloadCallback() {


        let ContactFormMAin = new Vue({
            el: '#contact-form',
            data: {
                fname: '',
                nameError: null,
                femail: '',
                emailError: null,
                fvalid: '',
                validError: null,
                fmessage: '',
                fsubject: '',
                resultMessage: null,
                resultType: null,
                token: null,

                tokenError: null,
            },
            methods: {
                validEmail: function (email) {
                    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(email);
                },
                checkForm: function (event) {

                    event.preventDefault();
                    this.token = grecaptcha.getResponse();

                    this.nameError = null;
                    this.emailError = null;
                    this.validError = null;
                    this.tokenError = null;

                    if (!this.token) {
                        this.tokenError = 'Please check captcha';
                    }
                    if (!this.fname) {
                        this.nameError = 'Please enter your name';
                    }
                    if (!this.fvalid) {
                        this.validError = 'Please confirm your age';
                    }
                    if (!this.femail) {
                        this.emailError = 'Please enter your email';
                    } else if (!this.validEmail(this.femail)) {
                        this.emailError = 'Email is invalid';
                    }


                    if (!this.nameError && !this.emailError && !this.validError && !this.tokenError) {

                        jQuery.ajax({
                            url: 'include/mail.php',

                            type: "POST",
                            data: {
                                name: ContactFormMAin.fname,
                                email: ContactFormMAin.femail,
                                message: ContactFormMAin.fmessage,
                                subject: ContactFormMAin.fsubject,
                                token: this.token
                            },
                            success: function (result) {
                                result = JSON.parse(result);
                                if (result.success) {
                                    ContactFormMAin.resultMessage = result.message;
                                    ContactFormMAin.resultType = result.type;
                                } else {
                                    ContactFormMAin.resultMessage = "Something was wrong. Please try again later";
                                    ContactFormMAin.resultType = 'danger';
                                }

                            }

                        })

                    }

                },
            },
        });

    }

    onloadCallback();

});


$(window).on("load", function () {
    //custom scroll line in window

});




